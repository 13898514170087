import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { CONN_KEY } from '../Conn'
import axios from "axios";
import { IonContent, IonPage, IonBackButton, IonToolbar, IonTitle } from '@ionic/react';
import Balance from '../components/Balance';

const BalanceMain: React.FC = () => {
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>(); // Retrieve selectedFlourId from URL path
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };
    useEffect(() => {
        // Check if the code is running in a browser environment
        if (typeof window !== 'undefined') {
            const gaULCookie = getCookie('_gaUL');
            if (!gaULCookie || gaULCookie !== id) {
                // Don't perform redirection if _gaUL cookie doesn't exist or doesn't match id
                return;
            }
        }

        setIsLoading(false);

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    setUser(res.data);
                    // If you have a calculateCountdown function, call it here
                    // calculateCountdown(res.data.endtime);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchUserData(); // Fetch data initially

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
        };
    }, [history, id]);

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className='tabbarmains'>
                    <IonBackButton defaultHref={user ? `/dashboard/${user.id}` : ""} />
                </div>
                <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Balans tarixcəsi</IonTitle>
            </IonToolbar>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} className='ion-padding'>
                <div className='container'>
                    <Balance />
                </div>
            </IonContent>
        </IonPage >
    );
};

export default BalanceMain;