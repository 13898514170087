import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CONN_KEY } from '../Conn';

interface StatComProps {
    id: string; // Define 'id' prop
}

const StatCom: React.FC<StatComProps> = ({ id }) => {
    const [rejectedCount, setRejectedCount] = useState<number | null>(null);

    useEffect(() => {
        const fetchRejectedCount = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}satisrezrvTodaycom.php?userid=${id}`);
                console.log('Notification Data:', response.data);
                setRejectedCount(response.data.rejectedCount);
            } catch (error) {
                console.log('Error fetching rejected count:', error);
            }
        };

        fetchRejectedCount();
    }, [id]); // Add 'id' as a dependency to re-fetch data when 'id' changes

    return (
        <div className='sadf gd1' style={{ background: "#717ccd" }}>
            <div className="contetnotyf">
                <div style={{ fontSize: '24px', color: '#fff' }}>{rejectedCount}</div>
                <span style={{ color: '#fff' }}>Rezerv sayı</span>
            </div>
        </div>
    );
};

export default StatCom;