import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    IonButton,
    IonChip,
    IonContent,
    IonHeader,
    IonIcon,
    IonLabel,
    IonList,
    IonModal,
    IonRefresher,
    IonRefresherContent,
    IonRouterLink,
    IonSkeletonText,
    IonTitle,
    IonToast,
    IonToolbar,
    IonButtons,
    useIonActionSheet
} from '@ionic/react';

import { chevronForwardOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import axios from 'axios';
import { CONN_KEY } from '../Conn';
import NotFound from './notfound';

interface Reservation {
    Masa: number;
    id: number;
    created_at: string;
    selectedDay: string;
    selectedFlourId: string;
    username: string;
    userid: string;
    status: 'pending' | 'complete' | 'rejected' | 'ended';
}

type GroupedReservations = { [date: string]: Reservation[] };

const CheckComp1: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    // Ref for the modal
    const modalRef = useRef<HTMLIonModalElement>(null);

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`);
            // Handle userStatus or perform additional operations if needed.
        } catch (error) {
            console.log('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchReservationsData = async () => {
        try {
            const response = await axios.post(`${CONN_KEY}getRezvr.php`, { id }, { headers: { 'Content-Type': 'application/json' } });
            const sortedReservations = response.data.reservations.sort((a: Reservation, b: Reservation) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setReservations(sortedReservations);
        } catch (error) {
            console.log('Error fetching reservation data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = async (event: CustomEvent) => {
        await fetchReservationsData();
        event.detail.complete();
    };

    useEffect(() => {
        fetchUserData();
        fetchReservationsData();
    }, [id]);

    const groupedReservations: GroupedReservations = reservations.reduce((acc, reservation) => {
        const date = new Date(reservation.created_at).toLocaleDateString();
        acc[date] = acc[date] || [];
        acc[date].push(reservation);
        return acc;
    }, {} as GroupedReservations);

    const handleToastClose = () => {
        setToastMessage(null);
    };

    const formatMasa = (masa?: number | string) => {
        if (masa === undefined || masa === null) {
            return '';
        }
        const masaStr = masa.toString();
        const prefix = masaStr.slice(0, 2);
        if (prefix === '10' || prefix === '20' || prefix === '30') {
            return masaStr.slice(2);
        }
        return masaStr;
    };

    const formatFlor = (masa: string) => {
        if (masa === 'E1V') {
            return 'V' + masa.slice(4);
        }
        return masa;
    };

    return (
        <div>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonRefresherContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonRefresher>

            {loading ? (
                <IonList onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonSkeletonText animated style={{ width: '10%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        <IonSkeletonText animated style={{ width: '90%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        <IonSkeletonText animated style={{ width: '80%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </IonLabel>
                </IonList>
            ) : (
                <div className="listrezv" style={{ marginTop: '18px' }}>
                    {Object.entries(groupedReservations).map(([date, reservationsForDate], index) => (
                        <React.Fragment key={index}>
                            <div className="timemain">{date}</div>
                            {reservationsForDate.map((reservation, innerIndex) => (
                                <React.Fragment key={innerIndex}>
                                    <IonRouterLink
                                        key={innerIndex}
                                        routerLink={`/dashboard/ch/${reservation.id}`}
                                        routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <div className="dsfujnh">
                                            <div className="jvfnd">
                                                <div className="head-list">
                                                    <span style={{ color: '#CBE7FB' }}>
                                                        {formatFlor(reservation.selectedFlourId)}
                                                    </span>
                                                    <span style={{ color: '#CBFBE7' }}>
                                                        {formatMasa(reservation.Masa)}
                                                    </span>
                                                </div>
                                                {/* Reservation Status */}
                                                {reservation.status === 'pending' && (
                                                    <IonChip color="warning" className="pending-chip" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Yoxlanılır</IonLabel>
                                                    </IonChip>
                                                )}
                                                {reservation.status === 'complete' && (
                                                    <IonChip color="success" className="complete-chip" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Rezerv edilib</IonLabel>
                                                    </IonChip>
                                                )}
                                                {reservation.status === 'rejected' && (
                                                    <IonChip color="danger" className="complete-chip" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>İmtina edildi</IonLabel>
                                                    </IonChip>
                                                )}
                                                {reservation.status === 'ended' && (
                                                    <IonChip color="danger" className="complete-chip" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Müddəti bitib</IonLabel>
                                                    </IonChip>
                                                )}
                                                {/* Time list */}
                                                <div className="time-list">
                                                    {new Date(reservation.selectedDay).toLocaleDateString('en-GB')}
                                                </div>
                                                {/* Button to trigger modal */}
                                            </div>
                                        </div>
                                    </IonRouterLink>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                    {reservations.length === 0 && <NotFound />}
                    <IonToast
                        isOpen={!!toastMessage}
                        onDidDismiss={handleToastClose}
                        message={toastMessage || ''}
                        duration={3000}
                        color={toastMessage?.toLowerCase().includes('problem') ? 'danger' : 'success'}
                    />
                </div>
            )}
        </div>
    );
};

export default CheckComp1;
