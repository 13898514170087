import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Barcode from 'react-barcode';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
    IonContent,
    IonPage,
    IonBackButton,
    IonList,
    IonLabel,
    IonBadge,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonButton,
    IonModal,
    IonHeader,
    IonCheckbox,
    IonItem,
    IonTextarea,
    IonAlert
} from '@ionic/react';
import { CONN_KEY } from '../Conn';

interface MasaInfo {
    Masa: string;
    selectedDay: string;
    selectedFlourId: string;
    id: string;
    selectedTime: string;
    status: string;
    username: string;
    created_at: string;
    sebeb: string;
    userId: string; // Add this property to get the user's ID
}

const Ch: React.FC = () => {
    const [MasaInfo, setMasaInfo] = useState<MasaInfo | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false); // State for the alert
    const [isPlanChanged, setIsPlanChanged] = useState(false);
    const [isCustomerRejected, setIsCustomerRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [responseData, setResponseData] = useState<string>(''); // State to store server response data
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>(); // Retrieve selectedFlourId from URL path
    const history = useHistory();
    // Fetch data
    const fetchData = async () => {
        try {
            setIsLoading(true);

            const api = axios.create({
                baseURL: `${CONN_KEY}`,
            });

            const MasaResponse = await api.get(`${CONN_KEY}getmazainfoid.php?id=${id}`);
            console.log('MasaInfo:', MasaResponse.data);
            setMasaInfo(MasaResponse.data);

            if (MasaResponse.data?.userId) {
                const userId = MasaResponse.data.userId;
                const userResponse = await api.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
                console.log('UserInfo:', userResponse.data);
                setUser(userResponse.data);
            }
        } catch (error) {
            console.log('Error fetching data', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    // Function to open the modal
    const openModal = () => {
        setModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalOpen(false);
    };

    // Function to handle the confirmation of rejection
    const handleRejectionConfirmation = async () => {
        if (!MasaInfo) {
            console.log('MasaInfo is not available');
            return;
        }

        // Check if rejection reason is empty
        if (rejectionReason.trim() === '') {
            // Show alert if rejection reason is empty
            setShowAlert(true);
            setResponseData('İmtina səbəbini qeyd edin.'); // Set the alert message
            return;
        }

        // Create the combined sebeb string by combining the reason and the states of the checkboxes
        let sebeb = rejectionReason;

        // Add checkboxes information to the sebeb string if they are checked
        if (isPlanChanged) {
            sebeb += '\nPlan dəyişdi';
        }

        if (isCustomerRejected) {
            sebeb += '\nMüştəri imtina etdi';
        }

        // Create payload with the necessary data
        const payload = {
            id: MasaInfo.id,
            sebeb: sebeb,
            manager: 'test', // Replace with the manager's name
            userId: MasaInfo.userId
        };

        try {
            // Send POST request with payload to the endpoint defined by `CONN_KEY`
            const response = await axios.post(`${CONN_KEY}permissionimtina.php`, payload);

            // Handle response
            console.log(response.data);
            // Check response data for specific messages
            if (response.data.message === 'Reservation rejection successful.') {
                setResponseData('Reservation rejection successful.');
                closeModal();
            } else if (response.data.message === 'Uğursuz: Masa artıq imtina edilib') {
                setResponseData('Uğursuz: Masa artıq imtina edilib');
                closeModal();
            } else {
                setResponseData('Unknown error occurred.');
            }

            // Show the alert
            setShowAlert(true);
        } catch (error) {
            console.log('Error handling rejection:', error);
            // Handle error scenario
            setResponseData('An error occurred while processing the request.');
            setShowAlert(true);
        }
    };

    const formatMasa = (masa: string) => {
        const prefix = masa.slice(0, 2);
        if (prefix === '10' || prefix === '20' || prefix === '30') {
            return masa.slice(2);
        }
        return masa;
    };
    const formatFlor = (masa: string) => {
        if (masa === 'E1V') {
            return 'V' + masa.slice(4);
        }

        return masa;
    };
    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };
    useEffect(() => {
        // Check if the code is running in a browser environment
        if (typeof window !== 'undefined') {
            const gaULCookie = getCookie('_gaUL');
            if (!gaULCookie || gaULCookie !== id) {
                // Don't perform redirection if _gaUL cookie doesn't exist or doesn't match id
                return;
            }
        }

        setIsLoading(false);

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    setUser(res.data);
                    // If you have a calculateCountdown function, call it here
                    // calculateCountdown(res.data.endtime);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchUserData(); // Fetch data initially

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
        };
    }, [history, id]);
    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className='tabbarmains'>
                    <IonBackButton defaultHref={user ? `/dashboard/${user.id}` : ""} />
                    {/* <IonButton fill="clear" onClick={openModal} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        Imtina et <IonIcon icon={ellipsisVertical} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </IonButton> */}
                </div>
            </IonToolbar>
            <IonContent fullscreen className="ion-padding ion-text-center" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className="cek-container">
                    {MasaInfo && (
                        <div className="cek-1">
                            <div className="list">
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Ərazi - Masa - Mərtəbə</IonLabel>
                                    <span>{formatFlor(MasaInfo.selectedFlourId)} - {formatMasa(MasaInfo.Masa)}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gün</IonLabel>
                                    <span>{new Date(MasaInfo.selectedDay).toLocaleDateString('en-GB')}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Id</IonLabel>
                                    <span>{MasaInfo.id}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Saat</IonLabel>
                                    <span>{MasaInfo.selectedTime}</span>
                                </div>
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Status</IonLabel>
                                    <IonBadge onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        {MasaInfo.status === 'ended' && new Date(MasaInfo.created_at).toLocaleDateString() !== new Date().toLocaleDateString() && (
                                            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Müddəti bitib</IonLabel>
                                        )}
                                        {MasaInfo.status === 'pending' && (
                                            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Yoxlanılır</IonLabel>
                                        )}
                                        {MasaInfo.status === 'complete' && (
                                            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Rezerv edilib</IonLabel>
                                        )}
                                        {MasaInfo.status === 'rejected' && (
                                            <>
                                                <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>İmtina edildi</IonLabel>
                                                <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{MasaInfo.sebeb}</IonLabel>
                                            </>
                                        )}
                                    </IonBadge>
                                </div>
                                <div className="sjnds">
                                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>User</IonLabel>
                                    <span>{user?.username} {user?.surename}</span>
                                </div>
                                <span>{MasaInfo.created_at}</span>
                            </div>
                            <img
                                src={`https://api.qrserver.com/v1/create-qr-code/?data=${MasaInfo.id}`}
                                alt={`QR code for Masa ${MasaInfo.Masa}`}
                                className="qrcartd"
                            />
                            <Barcode value={MasaInfo.id} height={50} />
                        </div>
                    )}
                </div>
            </IonContent>
            {/* Modal for additional options */}
            {/* <IonModal isOpen={isModalOpen} onDidDismiss={closeModal}>
                <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <div className="tabbarmains">
                            <IonBackButton defaultHref="/" />
                            {/* <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>İmtina</IonTitle> 
                        </div>
                         <IonButton onClick={closeModal} fill="clear" slot="end" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Bağla</IonButton> 
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    Modal content
                    <IonList inset={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonCheckbox
                                slot="start"
                                checked={isPlanChanged}
                                onIonChange={(e) => setIsPlanChanged(e.detail.checked)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Plan dəyişdi</IonLabel>
                        </IonItem>
                        <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonCheckbox
                                slot="start"
                                checked={isCustomerRejected}
                                onIonChange={(e) => setIsCustomerRejected(e.detail.checked)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Müştəri imtina etdi</IonLabel>
                        </IonItem>
                        <IonList inset={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonTextarea
                                    placeholder="İmtina səbəbini ətraflı şəkildə qeyd edin"
                                    value={rejectionReason}
                                    onIonChange={(e) => setRejectionReason(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </IonItem>
                        </IonList>
                    </IonList>

                    <IonButton
                        expand="block"
                        onClick={handleRejectionConfirmation}
                        disabled={rejectionReason.trim() === ''} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        İmtinanı təstiq et
                    </IonButton>
                </IonContent>
            </IonModal> */}
            {/* {/* Alert for response data  */}
            {/* <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header="Cavab"
                message={responseData}
                buttons={['OK']}
            /> */}
        </IonPage>
    );
};

export default Ch;
