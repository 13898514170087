import {
    IonContent,
    IonPage,
    IonIcon,
    IonSkeletonText,
    IonImg,
    IonButton,
    IonHeader,
    IonMenu,
    IonMenuButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonRouterLink,
} from '@ionic/react';
import { person, pricetags, map, notifications, eye, eyeOff } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { CONN_KEY } from '../Conn'
import CheckComp from '../components/check';
import { Pagination } from 'swiper/modules';
import '@ionic/react/css/ionic-swiper.css';
import SettingsComp from '../components/Serrings';
interface ResetProps extends RouteComponentProps<{ id: string; }> { }

const Mainpage: React.FC<ResetProps> = ({ match }) => {
    const history = useHistory();
    const [user, setUser] = useState<any>(null);
    const [countdown, setCountdown] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true); // Add a state for loading
    const [slideData, setSlideData] = useState<any[]>([]);
    const [notificationData, setNotificationData] = useState<any[]>([]);
    const [balanceVisible, setBalanceVisible] = useState(true);
    const [randomEmojis, setRandomEmojis] = useState('');
    const [currentHour, setCurrentHour] = useState<number>(new Date().getHours());
    const [RezervStart, setRezervStart] = useState<number>(0);
    const [RezervEnd, setRezervEnd] = useState<number>(0);
    const id = match.params.id;
    const userId = match.params.id;

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Fetch user data based on userId
          const userDataResponse = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
          setUser(userDataResponse.data);
        } catch (error) {
          console.log("Error fetching user data:", error);
        }
      };
  
      fetchData(); // Fetch data when userId changes
  
      return () => {
        // Cleanup function
        setUser(null); // Reset user state when component unmounts
      };
    }, [userId]);
    useEffect(() => {
        const fetchReservationSettings = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Settings.php`);
                const data = response.data;

                if (data && data.RezervStart && data.RezervEnd) {
                    setRezervStart(data.RezervStart);
                    setRezervEnd(data.RezervEnd);
                } else {
                    console.log('Invalid response format: Missing RezervStart or RezervEnd');
                }
            } catch (error) {
                console.log('Error fetching reservation settings:', error);
            }
        };

        fetchReservationSettings();
    }, []);
    useEffect(() => {
        let isMounted = true;
        const fetchNotificationData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Notification.php`);
                // console.log('Notification Data:', response.data);
                setNotificationData(response.data);
            } catch (error) {
                console.log('Error fetching notification data:', error);
            }
        };

        fetchNotificationData();
        // Fetch data every 1 minute
        const intervalId = setInterval(fetchNotificationData, 5000);

        // Clean up the interval when the component is unmounted
        return () => {
            clearInterval(intervalId);
            isMounted = false; // Set the flag to false when unmounting
        };
    }, []);
    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };
    useEffect(() => {
        let isMounted = true; // Add a boolean flag to track if the component is mounted

        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Storie.php`);
                if (isMounted) {
                    setSlideData(response.data);
                }
            } catch (error) {
                console.log('Error fetching slide data:', error);
            }
        };

        // Initial fetch
        fetchData();

        // Fetch data every 1 minute
        const intervalId = setInterval(fetchData, 5000);

        // Clean up the interval when the component is unmounted
        return () => {
            clearInterval(intervalId);
            isMounted = false; // Set the flag to false when unmounting
        };
    }, []);
    useEffect(() => {
        // Check if the _gaUL cookie exists
        const gaULCookie = getCookie('_gaUL');
        if (!gaULCookie || gaULCookie !== match.params.id) {
            // Redirect to the login page if the cookie doesn't exist or doesn't match userId
            history.push('/');
            return;
        }

        const delay = 50;

        setTimeout(() => {
            setIsLoading(false);
        }, delay);

        const api = axios.create({
            baseURL: `${CONN_KEY}`
        });

        const userId = match.params.id;

        const fetchData = () => {
            api.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`)
                .then(res => {
                    setUser(res.data);
                    calculateCountdown(res.data.endtime);
                })
                .catch(error => {
                    //  console.log("User data is not available.");
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchData(); // Fetch data initially

        const intervalId = setInterval(fetchData, 5000); // Fetch data every 2000 ms (2 seconds)

        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
        };
    }, [history, match.params.id]);
    const calculateCountdown = (endTime: string) => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const endTimeInMillis = new Date(endTime).getTime();
            const difference = endTimeInMillis - now;

            try {
                if (difference <= 0) {
                    clearInterval(intervalId);
                    setCountdown("Ended");
                } else {
                    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                    const formattedCountdown = `${days}G ${hours}S ${minutes}D ${seconds}s`;
                    setCountdown(formattedCountdown);
                }
            } catch (error) {
                console.log("Error calculating countdown:", error);
            }
        }, 1000);

        // Call the function once immediately to set the initial countdown
        calculateCountdown(endTime);
    };
    const navigateToProfile = () => {
        if (user) {
            const { id } = user; // Assuming `id` is the correct property in your user object
            history.push(`/dashboard/profile/${id}`);
        } else {
            console.log("User data is not available.");
        }
    };
    const navigateToRezerv = () => {
        if (user) {
            const { id } = user; // Assuming `id` is the correct property in your user object
            history.push(`/dashboard/rezervmap/${id}`);
        } else {
            //  console.log("User data is not available.");
        }
    };
    const navigateToScan = () => {
        if (user) {
            const { id } = user; // Assuming `id` is the correct property in your user object
            history.push(`/dashboard/CheckComp/${id}`);
        } else {
            //  console.log("User data is not available.");
        }
    };
    const navigateToSlidePage = (storieid: number) => {
        if (user) {
            const { id } = user;
            history.push(`/dashboard/Storie/userId=${id}&storieid=${storieid}`);
        } else {
            //  console.log("User data is not available.");
        }
    };
    const generateRandomEmojis = () => {
        const emojis = ['☕', '🍵', '🍶', '🎉', '🔥', '🍹', '😎', '🚀', '💪', '😄', '🥳', '🍻'];
        const randomEmojis = [];
        for (let i = 0; i < 3; i++) {
            const randomIndex = Math.floor(Math.random() * emojis.length);
            randomEmojis.push(emojis[randomIndex]);
        }
        return randomEmojis.join(' '); // Join emojis into a string
    };
    const toggleBalanceVisibility = () => {
        setBalanceVisible((prevState) => !prevState);
        if (!balanceVisible) {
            setRandomEmojis(generateRandomEmojis());
        }
    };
    const [segmentValue, setSegmentValue] = useState<string>('all'); // Define and initialize segmentValue
    const handleSegmentChange = (event: CustomEvent) => {
        setSegmentValue(event.detail.value as string); // Define handleSegmentChange function
    };
    const isTodayUserBirthday = (datebhrd: string): boolean => {
        const today = new Date();
        const [userDay, userMonth] = datebhrd.split('.'); // Split user's birthday into day and month

        return (
            parseInt(userDay, 10) === today.getDate() && // Check day
            (parseInt(userMonth, 10) - 1) === today.getMonth() // Check month (subtract 1 because months are zero-indexed)
        );
    };
    return (
        <>
            <IonMenu type='reveal' contentId="main-content" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                    <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} ></IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} className="ion-padding">
                    <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} className="ion-padding">
                        <SettingsComp userId={userId}/>
                    </IonContent>
                </IonContent>
            </IonMenu>
            <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} id="main-content">
                {/* <LocationUpdater userId={id} /> */}
                <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {user && (
                        <div className='imbgmain' >
                            <img src={`https://svurguns.com/Data/api/api/3x4/${user.filePP}`}
                                alt='plogo' />
                        </div>
                    )}
                    <IonButtons slot="start" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonMenuButton onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonMenuButton>
                    </IonButtons>
                    <IonButtons style={{ justifyContent: 'center' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <img src='assets/images/logo.png' style={{ width: '90px' }} alt='logo' />
                    </IonButtons>
                    <IonButtons slot="primary" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        {user && (
                            <div className="sdffscc circular-image-container" onClick={navigateToProfile}>
                                <img
                                    src={`https://svurguns.com/Data/api/3x4/${user.filePP}`}
                                    onError={(e) => {
                                        e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                                        e.currentTarget.onerror = null;
                                    }}
                                    alt="Profile Picture"
                                />
                            </div>
                        )}
                    </IonButtons>
                </IonToolbar>
                <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center container">
                    {user && (
                        <div className='imbgmain'>
                            <img src={`https://svurguns.com/Data/api/3x4/${user.filePP}`} alt='plogo' />
                        </div>
                    )}
                    <div className='container'>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                        // modules={[Pagination]}
                        >
                            <div className="sjter">
                                {Array.isArray(slideData) && slideData.length > 0 ? (
                                    slideData.map((slide, index) => (
                                        <SwiperSlide key={index} className='boximg' >
                                            <div onClick={() => navigateToSlidePage(slide.id)}>
                                                <div className='tjvgnd'>
                                                    <IonImg src={`${CONN_KEY}Storie/${slide.icon}`} alt={`Icon for ${slide.name}`} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                ) : null}
                            </div>
                        </Swiper>
                        <div className='safgd'>
                            <div className='fdgxc'>
                                <div style={{ fontSize: '16px', color: "#7f8c8d" }}>Point</div>
                                {balanceVisible ? (
                                    <div style={{ fontSize: '22px', fontWeight: 'bolder', marginTop: '10px' }}>  {user && (user.Balance)}</div>
                                ) : (
                                    <div style={{ fontSize: '20px', color: '#7f8c8d' }}>{randomEmojis}</div>
                                )}
                            </div>
                            <IonButton fill="clear" className='fcnjsd' onClick={toggleBalanceVisibility} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonIcon icon={balanceVisible ? eye : eyeOff} aria-hidden="true" style={{ fontSize: '20px', padding: '0', color: '#7f8c8d' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </IonButton>
                        </div>
                        <div className={`cardjdk${user && user.Status === 'ADMIN' ? ' cardAdmin' : ''}`}>
                            <div className='card'>
                                <div className="bvfdsg ">
                                    {user ? (
                                        <>
                                            <div style={{ fontSize: '24px', fontWeight: '700' }}>{user.username} {user.surename} </div>
                                            <div>#{user.userid}</div>
                                        </>
                                    ) : null}
                                </div>
                                {isLoading ? (
                                    <h4 className='dsfsz'>
                                        <span style={{ color: '#ff0000' }}>
                                            <IonSkeletonText animated style={{ width: '100%', height: '16px', display: 'inline-block' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                        </span>
                                    </h4>
                                ) : (
                                    user && (
                                        <div className='dsfsz'>
                                            {isNaN(parseInt(countdown)) || !countdown ? (
                                                <IonSkeletonText animated style={{ width: '100%', height: '16px', display: 'inline-block' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                            ) : (
                                                countdown === "Ended" ? (
                                                    <h6 className='whhsd'>Kartın müddəti bitib<span style={{ color: '#ff0000' }}>Kartınızı yeniləyin</span></h6>
                                                ) : (
                                                    <div className='fjvbn'>
                                                        {/* <> <IonIcon icon={timer} style={{ color: '#fff', fontSize: '30px' }} /></> */}
                                                        <div className='whhsd'>
                                                            <div>Kartın bitmə vaxdı</div>
                                                            <div style={{ fontSize: '24px', fontWeight: 'bolder' }}>{countdown}</div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            {user.Status !== null ? (
                                                <h4 style={{ color: '#ff0000' }} >{user.Status}</h4>
                                            ) : (
                                                <div >Aktive</div>
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        <div className='sffgdj'>
                            {/* <div className='sadf gd1' ><IonIcon icon={person} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />Profile</div> */}
                            <div className={`sadf gd2 ${user && user.Status === 'Blocked App' ? 'jvfnds' : ''}`} onClick={navigateToRezerv}>
                                <IonIcon icon={map} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                Rezerv et
                                {currentHour >= RezervStart && currentHour < RezervEnd && (
                                    <div className="circle circle2"></div>
                                )}
                            </div>
                            <div className='sadf gd3' onClick={navigateToScan}><IonIcon icon={pricetags} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />Əməliyatlar</div>
                        </div>
                        <div className={notificationData.length > 0 ? 'dsfujsd' : 'hidden'}>
                            <IonRouterLink routerLink={`/dashboard/Notify/${id}`} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <div className='jvfnds'>
                                    <IonIcon icon={notifications} style={{ color: '#fff' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                </div>
                                {Array.isArray(notificationData) && notificationData.length > 0 ? (
                                    <div className="contetnotyf">
                                        <div className="TitleNotf">{notificationData[0].Title}</div>
                                        <div className="SubNotf">{notificationData[0].subtitle}</div>
                                    </div>
                                ) : null}
                            </IonRouterLink>
                        </div>
                        {user && (
                            <>
                                {isTodayUserBirthday(user.datebhrd) && ( // Check if today is user's birthday
                                    <div className='dsfujsd'>
                                        <div className="birthday-notification">
                                            {/* <div className="birthday-icon">
                                                <img style={{ width: '50px', height: '50px' }} src='assets/icon/wired-gradient-1103-confetti.gif' alt="confetti" />
                                            </div> */}
                                            <div className="birthday-message">
                                                <h2 className="title">🎉 Happy Birthday {user.username}! 🎉</h2>
                                                <p className="subtitle">Extra Baku- doğum gününüz təbrik edir! 🥳✨ {user.username} qarşıdan gələn sevinc, uğurlar və heyrətamiz anlarla dolu unudulmaz bir il arzulayırıq!</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <div className='listheader'>
                            <h3>Əməliyyatlar</h3>
                        </div>
                        {/* <ReservationTester /> */}
                        {/* <IonSegment value={segmentValue} onIonChange={handleSegmentChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonSegmentButton value="all" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Rezervlər</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="favorites" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Balans</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                        {segmentValue === "all" && }
                        {segmentValue === "favorites" && <Balance />} */}
                        <CheckComp />
                    </div>
                </IonContent>
            </IonPage >
        </>
    );
};

export default Mainpage;