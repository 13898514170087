import { IonContent, IonPage, IonRow, IonCol, IonItem, IonInput, IonButton, IonAlert, IonNote, IonLoading, IonModal, IonHeader, IonBackButton, IonButtons, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CONN_KEY } from '../Conn';

function ResetPass() {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const handleResetPassword = () => {
    // Validate passwords
    if (newPassword !== confirmPassword) {
      setIsError(true);
      setMessage("Passwords do not match.");
      return;
    }

    // Send the new password and email to the server to initiate the password reset process
    axios.post(`${CONN_KEY}resetPass.php`, { id, newPassword })
      .then(res => {
        setMessage(res.data.message);
        setShowModal(true);
        setIsError(true);
        history.push(`/Login`);
      })
      .catch(error => {
        setIsError(true);
        setMessage("Failed to reset password. Please try again later.");
      });
  };

  return (
    <IonPage className='aczxcdef' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonButtons slot="start" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Parolu yenilə</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center container loginsd" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='container'>
          <IonAlert
            isOpen={isError}
            onDidDismiss={() => setIsError(false)}
            cssClass="my-custom-class"
            header={"Oops"}
            message={message}
            buttons={["ok"]}
          />
          <h1 style={{ fontWeight: 800 }}>Extra Baku</h1>
          <small>By Marketing team</small>
          <div className='loginformgdsd'>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonInput
                    placeholder="Yeni şifrə"
                    type="password"
                    value={newPassword}
                    onIonChange={(e) => setNewPassword(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonInput
                    placeholder="Şifrəni təsdiqləyin"
                    type="password"
                    value={confirmPassword}
                    onIonChange={(e) => setConfirmPassword(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" onClick={handleResetPassword} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Parolu sıfırlamak</IonButton>
              </IonCol>
            </IonRow>
            {/* <IonNote onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <span style={{ color: '#6A3699', fontWeight: 'bold' }} onClick={() => setShowModal(true)}>Forgot Password?</span>
            </IonNote> */}
            <IonLoading
              isOpen={isLoading}
              onDidDismiss={() => setIsLoading(false)}
              message={'Loading...'}
              duration={5000}
              spinner="dots"
            />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default ResetPass;
