import {
  IonContent, IonPage, IonRow, IonCol, IonItem, IonInput, IonButton, IonAlert, IonLabel, IonLoading,
  IonCheckbox, IonNote, IonModal, IonIcon
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { CONN_KEY } from '../Conn';
import { CONN_KEY_version } from '../Conn';
import { eyeOutline, callOutline, eyeOffOutline } from 'ionicons/icons';
import { Device } from '@capacitor/device';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

function Login() {
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [Phone, setPhone] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [Pass, setPass] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [resetMessage, setResetMessage] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [cameraInfo, setCameraInfo] = useState<any>(null);

  useEffect(() => {
    // Cleanup function to cancel ongoing asynchronous tasks
    let isMounted = true;

    // Check localStorage for rememberMe preference on component mount
    const rememberMePreference = localStorage.getItem('rememberMe');
    setRememberMe(rememberMePreference === 'true');

    // Check localStorage for saved username and Fin
    if (rememberMePreference === 'true') {
      const savedPass = localStorage.getItem('Pass');
      const savedemail = localStorage.getItem('email');

      if (savedemail) {
        setemail(savedemail);
      }

      if (savedPass) {
        setPass(savedPass);
      }
    }

    return () => {
      isMounted = false; // Set mounted flag to false when unmounting
    };
  }, [rememberMe]);

  useEffect(() => {
    // Check localStorage for rememberMe preference on component mount
    const rememberMePreference = localStorage.getItem('rememberMe');
    setRememberMe(rememberMePreference === 'true');

    // Check localStorage for saved Phone if rememberMe is true
    if (rememberMePreference === 'true') {
      const savedPhone = localStorage.getItem('Phone');
      if (savedPhone) {
        setPhone(savedPhone);
      }
    }
  }, []);

  const formatPhoneNumber = (phoneValue: string) => {
    // Remove all non-digit characters from the input
    const numericValue = phoneValue.replace(/\D/g, '');

    // Format the phone number as "000 000 00 00"
    const formattedValue = numericValue.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');

    // Update the state with the formatted phone number
    setPhone(formattedValue);
  };

  const handlePhoneChange = (e: CustomEvent<any>) => {
    const phoneValue = (e.target as HTMLInputElement).value;
    formatPhoneNumber(phoneValue);
  };

  const getLoggedInDevices = async () => {
    const info = await Device.getInfo();
    return { model: info.model, platform: info.platform };
  };

  const handleLogin = async () => {
    setIsLoading(true);

    // Retrieve device information
    const { model, platform } = await getLoggedInDevices();

    // Get camera information

    const loginData = {
      loggedInDevices: { model, platform },
      Pass: Pass,
      Phone: Phone,
    };

    // Save rememberMe preference to localStorage
    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('Pass', Pass);
      localStorage.setItem('Phone', Phone);
    } else {
      localStorage.removeItem('rememberMe');
      setRememberMe(false);
    }

    axios.post(`${CONN_KEY}login.php`, loginData)
      .then(res => {
        const { status, id, message } = res.data;

        if (status === 1) {
          // Post to index database
          postToIndexDatabase(username, id);

          // Set the cookie
          setCookie('_gaUL', id, 30); // 30 days expiration, you can adjust this

          // Redirect to the dashboard with the user's id
          history.push(`/dashboard/${id}`);
        } else {
          setMessage(message);
          setIsError(true);
        }
      })
      .catch(error => {
        setMessage("Uğursuz Şəbəkəni yenidən yoxlayın");
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false regardless of success or failure
      });
  };

  const handleResetPassword = () => {
    axios.post(`${CONN_KEY}resPass.php`, { Phone, email })
      .then(res => {
        setResetMessage(res.data.message);
      })
      .catch(error => {
        setResetMessage("Failed to reset password. Please try again later.");
      });
  };

  const postToIndexDatabase = (username: string, userId: string) => {
    // console.log(`Posting to index database: Username - ${username}, UserID - ${userId}`);
  };

  const setCookie = (name: string, value: string, days: number) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <IonPage className='aczxcdef' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent className="ion-padding ion-text-center container loginsd" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='container'>
          <img
            src='assets/icon/icon.png'
            alt="Ionic logo"
            loading="lazy"
            className='sabfdkg'
          />
          <IonAlert
            isOpen={isError}
            onDidDismiss={() => setIsError(false)}
            cssClass="my-custom-class"
            header={"Oops"}
            message={message}
            buttons={["ok"]}
          />
          <h1 style={{ fontWeight: 800 }}>Extra Baku</h1>
          <small>By Marketing team</small>
          <div className='loginformgdsd'>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Telefon</IonLabel>
                  <IonInput
                    type="tel" // Change type from "number" to "tel"
                    value={Phone}
                    placeholder=""
                    maxlength={10}
                    onIonChange={handlePhoneChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                    <button style={{ height: '20px', background: 'transparent' }} >
                      <IonIcon slot="icon-only" style={{ fontSize: '16px' }} icon={callOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </button>
                  </IonInput>
                </IonItem>
                <IonNote class="ion-margin-horizontal" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  050 000 00 00 kimi yazılmalıdr
                </IonNote>
              </IonCol>
            </IonRow>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Password</IonLabel>
                  <IonInput
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Pass"
                    value={Pass}
                    onIonChange={(e) => setPass(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                  >
                    <button style={{ height: '20px', background: 'transparent' }} onClick={toggleShowPassword} >
                      <IonIcon slot="icon-only" style={{ fontSize: '16px' }} icon={showPassword ? eyeOffOutline : eyeOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </button>
                  </IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {/* Remember Me Checkbox */}
                <IonItem lines="none" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Yadda saxla</IonLabel>
                  <IonCheckbox
                    checked={rememberMe}
                    onIonChange={(e) => {
                      setRememberMe(e.detail.checked);
                      localStorage.setItem('rememberMe', e.detail.checked ? 'true' : 'false');
                    }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonNote color="medium" class="ion-margin-horizontal" style={{ marginBottom: '18px', fontWeight: 'bold' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Hesabınız yoxdur? <Link to={'/Register'}>Qeydiyyatdan keç</Link>
            </IonNote>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonButton expand="block" onClick={handleLogin} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Giriş et
                </IonButton>
              </IonCol>
            </IonRow>
            <IonNote class="ion-margin-horizontal" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <span style={{ marginBottom: '18px', color: '#6A3699', fontWeight: 'bold' }} onClick={() => setShowModal(true)}>Parolu unutmusunuz?</span>
            </IonNote>
            <IonLoading
              isOpen={isLoading}
              onDidDismiss={() => setIsLoading(false)}
              message={'Loading...'}
              duration={5000}
              spinner="dots"
            />
          </div>
          <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <IonContent className="ion-padding" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <h2>Parolun sıfırla</h2>
              <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonInput
                  type="email"
                  value={email}
                  placeholder="Email"
                  onIonChange={(e) => setemail(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </IonItem>
              <IonButton expand="block" onClick={handleResetPassword} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Parolun sıfırla</IonButton>
              <IonButton expand="block" fill="outline" onClick={() => setShowModal(false)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Close</IonButton>
              <IonAlert
                isOpen={resetMessage !== ""}
                onDidDismiss={() => setResetMessage("")}
                header="Parolun Sıfırlanması e-poçtla göndərildi"
                message={resetMessage}
                buttons={['OK']}
              />
            </IonContent>
          </IonModal>
          <IonNote style={{ fontSize: "small" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            GİRİŞ düyməsini klikləməklə Siyasətimizlə razılaşırsınız
            <br />
            Version : {CONN_KEY_version}
          </IonNote>
        </div>
      </IonContent>
    </IonPage >
  );
}

export default Login;
