import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAW_w2qFIZUwVKdMOirkRylUuj6ZwOS67I",
  authDomain: "senateapp-3f2f9.firebaseapp.com",
  projectId: "senateapp-3f2f9",
  storageBucket: "senateapp-3f2f9.appspot.com",
  messagingSenderId: "336301730905",
  appId: "1:336301730905:web:1e8ff6366b195ff2791548",
  measurementId: "G-FXWJFTTWQ9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
