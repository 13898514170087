import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonRouterLink,
    IonChip,
    IonLabel,
    IonList,
    IonItemOptions,
    IonItemOption,
    IonSkeletonText,
    IonItemSliding,
    IonItem,
    IonToast,
    IonContent
} from '@ionic/react';
import { caretDown, caretUp } from 'ionicons/icons';
import axios from 'axios';
import { CONN_KEY } from '../Conn';
import NotFound from './notfound';

interface Reservation {
    // action: number;
    id: number;
    created_at: string;
    Value: string;
    selectedFlourId: string;
    username: string;
    userid: string;
    Balance: string;
    action: 'minus' | 'plus';
}

type GroupedReservations = { [date: string]: Reservation[] };

const Balance: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`);
            const userStatus = response.data.Status;

        } catch (error) {
             console.log('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [id]);

    const fetchReservations = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${CONN_KEY}Balans.php`,
                { id },
                { headers: { 'Content-Type': 'application/json' } }
            );

            // Sort reservations based on the created_at time
            const sortedReservations = response.data.reservations.sort((a: Reservation, b: Reservation) =>
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            setReservations(sortedReservations);
        } catch (error) {
             console.log('Error fetching reservation data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = async (event: CustomEvent) => {

        await fetchReservations();

        setTimeout(() => {
            event.detail.complete();
        }, 200);
    };

    useEffect(() => {
        fetchUserData();
        fetchReservations();
    }, [id]);

    // Group reservations by created_at date
    const groupedReservations: GroupedReservations = reservations.reduce((acc, reservation) => {
        const date = new Date(reservation.created_at).toLocaleDateString();
        acc[date] = acc[date] || [];
        acc[date].push(reservation);
        return acc;
    }, {} as GroupedReservations);

    const handleToastClose = () => {
        setToastMessage(null);
    };

    return (
        <div>
            <IonRefresher style={{ zIndex: 999 }} slot="fixed" onIonRefresh={handleRefresh} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonRefresherContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonRefresher >
            {
                loading ? (
                    <IonList onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonSkeletonText animated={true} style={{ width: '10%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonSkeletonText animated={true} style={{ width: '90%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <IonSkeletonText animated={true} style={{ width: '80%' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </IonLabel>
                    </IonList >
                ) : (
                    <div className="listrezv" style={{ marginTop: '18px' }}>
                        {Object.entries(groupedReservations).map(([date, reservationsForDate], index) => (
                            <React.Fragment key={index}>
                                <div className="timemain">{date}</div>
                                {reservationsForDate.map((reservation, innerIndex) => (
                                    <React.Fragment key={innerIndex}>
                                        <div className="dsfujnh">
                                            <div className="jvfnd">
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: " center",
                                                    flexDirection: 'column',
                                                    gap: '4px'
                                                }}>
                                                    <div className="heads-list" style={{
                                                        display: "flex",
                                                        alignItems: " center"
                                                    }} >
                                                        {reservation.action === 'minus' && (
                                                            <>
                                                                <IonIcon icon={caretDown} aria-hidden="true" style={{ color: '#ff0000', fontSize: '16px', }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                                                <span style={{ color: '#ff0000', fontSize: '18px' }}>
                                                                    {' '}
                                                                    {reservation.Balance}  
                                                                </span>
                                                            </>
                                                        )}
                                                        {reservation.action === 'plus' && (
                                                            <>
                                                                <IonIcon icon={caretUp} aria-hidden="true" style={{ color: '#2dd36f', fontSize: '16px', }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                                                <span style={{ color: '#2dd36f', fontSize: '18px' }}>
                                                                    {' '}
                                                                    {reservation.Balance}  
                                                                </span>
                                                            </>
                                                        )}
                                                    </div>
                                                    <span style={{ fontSize: '12px' }}>{reservation.created_at}</span>
                                                </div>
                                                {reservation.action === 'minus' && (
                                                    <div style={{ color: '#ff0000', fontSize: '16px' }} className="complete-chip"  >
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>- {reservation.Value}  </IonLabel>
                                                    </div>
                                                )}
                                                {reservation.action === 'plus' && (
                                                    <div style={{ color: '#2dd36f', fontSize: '16px' }} className="complete-chip"  >
                                                        <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>+ {reservation.Value}  </IonLabel>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </React.Fragment >
                        ))}
                        {reservations.length === 0 && <NotFound />}
                        <IonToast
                            isOpen={!!toastMessage}
                            onDidDismiss={handleToastClose}
                            message={toastMessage || ''}
                            duration={3000}
                            color={toastMessage?.toLowerCase().includes('problem') ? 'danger' : 'success'}
                        />
                    </div >
                )
            }
        </div >
    );
};

export default Balance;
