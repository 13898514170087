import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import OneSignal from 'onesignal-cordova-plugin';
import axios from 'axios';
import { CONN_KEY, CONN_KEY_version } from '../src/Conn';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Mainpage from './pages/main';
import Dashboard from './pages/Profile';
import RezervP from './pages/RezervP';
import Notify from './pages/Notifications';
import Masa from './pages/MasassExtra/Masa';
import Masa2 from './pages/MasassExtra/Masa2';
import Ch from './pages/Ch';
import CheckComp from './pages/CeckPage';
import PersonInformation from './components/PersonInformation';
import SlideStories from './components/SliedStorie';
import { useHistory } from 'react-router-dom';
import BalanceMain from './pages/BalanceMain';
import DriversMain from './pages/DriversMain';
import PersonInDriver from './components/PersonalDrivers';
import Register from './pages/Register';
import ResetPass from './components/passrest';
import RezervM from './pages/RezervMaps';
import RezervExtra from './pages/RezervP';
import RezervEllips from './pages/RezervEllips';
import MasaEllips1 from './pages/MasassEllips/Masa1';
import MasaEllips2 from './pages/MasassEllips/Masa2';
import MasaEllips3 from './pages/MasassEllips/Masa3';

const App: React.FC = () => {
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  useEffect(() => {
    // axios.get(CONN_KEY + 'script.php')
    //   .then((response) => {
    //     // Inject the CSS content into a style tag in the head of the document
    //     const styleTag = document.createElement('style');
    //     styleTag.type = 'text/css';
    //     styleTag.innerHTML = response.data;
    //     document.head.appendChild(styleTag);
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching theme variables:', error);
    //   });

    // axios.get(CONN_KEY + 'version.php')
    //   .then((response) => {
    //     const serverVersion = response.data.version;
    //     if (serverVersion !== CONN_KEY_version) {
    //       setShowUpdateAlert(true);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log('Error fetching version:', error);
    //   });
    axios.get(CONN_KEY + 'version.php')
      .then((response) => {
        const serverVersion = response.data.version;
        if (serverVersion !== CONN_KEY_version) {
          window.location.reload(); // Reload the page if a new version is detected
        }
      })
      .catch((error) => {
        console.error('Error fetching version:', error);
      });
    // OneSignal.Debug.setLogLevel(6);
    // OneSignal.initialize("8bcbfd91-7496-484d-ac0c-aa89f6a8b767");

    // OneSignal.Notifications.addEventListener('click', async (e) => {
    //   let clickData = await e.notification;
    //   console.log("Notification Clicked : ", clickData);
    // });

    // OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
    //   console.log("Notification permission granted " + success);
    // });
  }, []);

  return (
    <IonApp onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonReactRouter>
        <IonRouterOutlet onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <Route path="/login" component={Login} exact={true} />
          <Route path="/Register" component={Register} exact={true} />
          <Route path="/dashboard/profile/:id" component={Dashboard} exact={true} />
          <Route path="/dashboard/Ellips/:id" component={RezervEllips} exact={true} />
          <Route path="/dashboard/Extra/:id" component={RezervExtra} exact={true} />
          <Route path="/dashboard/rezervmap/:id" component={RezervM} exact={true} />
          <Route path="/dashboard/CheckComp/:id" component={CheckComp} exact={true} />
          <Route path="/dashboard/Drivers/:id" component={DriversMain} exact={true} />
          <Route path="/dashboard/Balance/:id" component={BalanceMain} exact={true} />
          <Route path="/dashboard/Notify/:id" component={Notify} exact={true} />
          <Route path="/dashboard/sect/:id&:selectedFlourId" component={Masa} exact={true} />
          <Route path="/dashboard/sect2/:id&:selectedFlourId" component={Masa2} exact={true} />
          <Route path="/dashboard/sectEllips/:id&:selectedFlourId" component={MasaEllips1} exact={true} />
          <Route path="/dashboard/sectEllips2/:id&:selectedFlourId" component={MasaEllips2} exact={true} />
          <Route path="/dashboard/sectEllips3/:id&:selectedFlourId" component={MasaEllips3} exact={true} />
          <Route path="/dashboard/:id" component={Mainpage} exact={true} />
          <Route path="/dashboard/Resetpass/:id" component={ResetPass} exact={true} />
          <Route path="/dashboard/ch/:id" component={Ch} exact={true} />
          <Route path="/dashboard/ch1/:id" component={Ch} exact={true} />
          <Route path="/dashboard/Storie/userid=:id&storieid=:storieid" component={SlideStories} exact={true} />
          <Route path="/dashboard/PersInfo/:id" component={PersonInformation} exact={true} />
          <Route path="/dashboard/PersDriver/:id" component={PersonInDriver} exact={true} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />
        </IonRouterOutlet>
      </IonReactRouter>
       {/* <IonAlert
        isOpen={showUpdateAlert}
        onDidDismiss={() => setShowUpdateAlert(false)}
        header={'Yenilənmə tələb olunur'}
        message={'Əlçatan yeni yeniləməniz var. Lütfən, yeniləməni tətbiq etmək üçün Google Play Store-a keçin.'}
        buttons={[
          {
            text: 'Update',
            handler: () => {
              // Open the Google Play Store link
              window.open('https://play.google.com/store/apps/details?id=extra.club.baku', '_blank');
            }
          }
        ]}
      />  */}
    </IonApp>
  );
};

export default App;