import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { IonImg, IonProgressBar, IonPage, IonContent, IonIcon } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import { close } from 'ionicons/icons';
import axios from 'axios';
import { CONN_KEY } from '../Conn'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface SlideStoriesProps {
    userId: string;
}

const SlideStories: React.FC<SlideStoriesProps> = ({ userId }) => {
    const [startX, setStartX] = useState<number | null>(null);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [progressValues, setProgressValues] = useState<number[]>([0]);
    const [slideData, setSlideData] = useState<any[]>([]);
    const progressCircle = useRef<HTMLDivElement | null>(null);
    const progressContent = useRef<HTMLDivElement | null>(null);
    const swiperRef = useRef<any>(null);
    const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
        if (progressCircle.current && progressContent.current) {
            progressCircle.current.style.setProperty('--progress', String(1 - progress));
            progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
        }
    };
    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Storie.php`);
                console.log('API Response:', response.data);

                if (isMounted) {
                    setSlideData(response.data);
                }
            } catch (error) {
                 console.log('Error fetching slide data:', error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);
    const handleTouchMove = (e: React.TouchEvent<HTMLIonContentElement>) => {
        if (startX !== null) {
            const currentX = e.touches[0].clientX;
            const deltaX = currentX - startX;
            if (deltaX > 1) {
                history.push(`/dashboard/${id}`);
            }
        }
    };
    const handleclose = (e: React.MouseEvent<HTMLIonIconElement, MouseEvent>) => {
        history.push(`/dashboard/${id}`);
    };
    const handleSlideTap = () => {
        // Increment the current slide index
        const nextSlide = (currentSlide + 1) % slideData.length;
        setCurrentSlide(nextSlide);

        if (swiperRef.current) {
            swiperRef.current.slideTo(nextSlide);
        }
        // Update progress values
        setProgressValues((prev) => {
            const newValues = [...prev];
            newValues[currentSlide] = 100;
            return newValues;
        });
    };
    const [progress, setProgress] = useState(0);
    let interval: NodeJS.Timeout;
    useEffect(() => {
        interval = setInterval(() => {
            setProgress((prevProgress) => prevProgress + 0.01);

            if (progress > 1) {
                clearInterval(interval); // Clear the interval to stop updating progress
            }
        }, 24);

        return () => clearInterval(interval);
    }, [progress]);

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  >

            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className='closestorie'>
                    <IonIcon icon={close} style={{ color: '#fff', fontSize: '20px' }} onClick={handleclose} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </div>
                {Array.isArray(slideData) && slideData.length > 0 ? (
                    <Swiper
                        pagination={{
                            type: 'progressbar',
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                    >
                        {slideData.map((slide, index) => (
                            <SwiperSlide key={index} >
                                <IonImg src={`${CONN_KEY}Storie/${slide.name}`} alt={slide.name} className='imgslidebg' onClick={handleSlideTap} onTouchMove={handleTouchMove} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                <IonImg src={`${CONN_KEY}Storie/${slide.name}`} alt={`Icon for ${slide.name}`} className='imbg' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </SwiperSlide>
                        ))}
                    </Swiper>

                ) : (
                    <div>Loading...</div>
                )}
            </IonContent>
        </IonPage >
    );
};

export default SlideStories;
