import React, { useState } from 'react';
import axios from "axios";
import { IonBackButton, IonButtons, IonPage, useIonViewDidEnter, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonLabel } from '@ionic/react';
import { CONN_KEY } from '../Conn';
import { RouteComponentProps } from "react-router-dom";
interface ResetProps extends RouteComponentProps<{ id: string }> { }

const PersonInDriver: React.FC<ResetProps> = ({ match }) => {
    // const history = useHistory();
    const [user, setUser] = useState<any>(null);
    const [countdown, setCountdown] = useState<string>("");

    useIonViewDidEnter(() => {
        const api = axios.create({
            baseURL: `${CONN_KEY}` // Replace with your PHP API base URL
        });

        const userId = match.params.id;

        api.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`)
            .then(res => {
                setUser(res.data);
                calculateCountdown(res.data.endtime);
            })
            .catch(error => {
                console.log("Error fetching user data");
            });
    });

    const calculateCountdown = (endTime: string) => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime();
            const endTimeInMillis = new Date(endTime).getTime();
            const difference = endTimeInMillis - now;

            try {
                if (difference <= 0) {
                    clearInterval(intervalId);
                    setCountdown("Ended");
                } else {
                    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                    const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
                    setCountdown(formattedCountdown);
                }
            } catch (error) {
                console.log("Error calculating countdown:", error);
            }
        }, 1000);

        // Call the function once immediately to set the initial countdown
        calculateCountdown(endTime);
    };
    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonButtons slot="start" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Personal information</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center">
                {user && (
                    <IonList inset={true} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <img alt='pp' className='pp'
                                onError={(e) => {
                                    e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                                    e.currentTarget.onerror = null;
                                }}
                                src={`https://svurguns.com/Data/api/3x4/${user.filePP}`}
                            />
                        </IonLabel>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.username}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Ata adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.dadname}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Soy Adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.surename}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Fin:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.Fin}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Telefon:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.Phone}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Reyting:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.Reyting}</IonLabel>
                        </IonItem>
                        {user.vezife !== 'Tour Guide' && (
                            <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Kartın Bitmə tarixi:</IonLabel>
                                <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{countdown}</IonLabel>
                            </IonItem>
                        )}
                    </IonList>
                )}
            </IonContent>
        </IonPage>
    );
}

export default PersonInDriver;