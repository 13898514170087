import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonBackButton, IonHeader, IonAlert, IonItem, IonToolbar, IonTitle, IonButton, IonRefresher, IonRefresherContent, IonLabel, IonInput, IonLoading, IonNote, IonTextarea, IonButtons } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { CONN_KEY, CONN_KEY_version } from '../../Conn'
import axios from "axios";
import { useHistory } from 'react-router-dom';

const TimeOfDay = ['23.30'];

const Masa: React.FC = () => {
  const [selectedDay, setSelectedDay] = useState(new Date().getDay());
  const [user, setUser] = useState<any>(null);
  const { id, selectedFlourId } = useParams<{ id: string, selectedFlourId: string }>(); // Retrieve selectedFlourId from URL path
  const history = useHistory();
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [userId, setUserId] = useState<string | null>(null);
  const [Masa, setMasa] = useState<number>(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [reservedMasaIds, setReservedMasaIds] = useState<number[]>([]);
  const [selectedTable, setSelectedTable] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const [todayDate, setTodayDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [currentHour, setCurrentHour] = useState<number>(new Date().getHours());
  const [RezervStart, setRezervStart] = useState<number>(0);
  const [RezervEnd, setRezervEnd] = useState<number>(0);
  const [masaStatuses, setMasaStatuses] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchReservationSettings = async () => {
      try {
        const response = await axios.get(`${CONN_KEY}Settings.php`);
        const data = response.data; // Access response data directly from axios response

        if (data && data.RezervStart) {
          setRezervStart(data.RezervStart); // Set RezervStart state with fetched value
          setRezervEnd(data.RezervEnd); // Set Rezervend state with fetched value
        } else {
          console.log('Invalid response format: Missing RezervStart');
        }
      } catch (error) {
        console.log('Error fetching reservation settings:', error);
      }
    };

    fetchReservationSettings();
  }, []);
  useEffect(() => {
    // Define a function to fetch the version data after a delay
    const fetchVersionDataWithDelay = () => {
      // Use setTimeout to delay the fetching of data by 5 seconds (5000 milliseconds)
      setTimeout(() => {
        axios.get(CONN_KEY + 'version.php')
          .then((response) => {
            const serverVersion = response.data.version;
            if (serverVersion !== CONN_KEY_version) {
              setShowUpdateAlert(true);
            }
          })
          .catch((error) => {
            console.log('Error fetching version:', error);
          });
      }, 5000); // 5 seconds delay
    };

    // Call the function to fetch the version data
    fetchVersionDataWithDelay();
  }, []);
  const getCookie = (name: string): string | null => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
    return null;
  };
  useEffect(() => {
    const fetchUserData = async () => {
      const fetchedUserId = id;
      setUserId(fetchedUserId);

      // Check if the _gaUL cookie exists
      const gaULCookie = getCookie('_gaUL');
      if (!gaULCookie || gaULCookie !== fetchedUserId) {
        // Redirect to the login page if the cookie doesn't exist or doesn't match userId
        // history.push('/');
        return;
      }
    };
    fetchUserData();

    // Get current UTC day
    const currentUTCDay = new Date().getUTCDay();
    // Calculate Baku day (considering that Baku is UTC+4)
    const bakuDay = (currentUTCDay + 1) % 7; // Adding 1 to shift Sunday from 0 to 1

    setSelectedDay(bakuDay);
    setCurrentTime(new Date());
  }, [id]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1);

    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    const todayInBaku = new Date().toLocaleString('az-AZ', { timeZone: "Asia/Baku" });
    const formattedDate = new Date(todayInBaku).toLocaleDateString('az-AZ', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    setTodayDate(formattedDate);
  }, []);
  const fetchReservedMasaIds = async (event?: CustomEvent) => {
    try {
      const response = await axios.get(`${CONN_KEY}GetmasaM2.php`, {});

      if (response.status === 200 && response.data && Array.isArray(response.data.reservations)) {
        const statusMap: { [key: number]: string } = {};
        // Map Masa IDs to their respective statuses
        response.data.reservations.forEach((reservation: { Masa: string; status: string }) => {
          const masaId = parseInt(reservation.Masa, 10);
          statusMap[masaId] = reservation.status;
        });

        setMasaStatuses(statusMap);
      } else {
        console.log('Invalid response format. Expected an object with reservations array.');
      }
    } catch (error) {
      console.log('Error fetching reserved Masa IDs:', error);
    } finally {
      if (event && event.detail && typeof event.detail.complete === 'function') {
        event.detail.complete();
      } else {
        console.log('Unable to complete event: event.detail.complete is not a function.');
      }
    }
  };
  useEffect(() => {
    // Fetch data when the component mounts
    fetchReservedMasaIds();
  }, [selectedFlourId]);
  const handleRefresh = (event?: CustomEvent) => {
    // Call fetchReservedMasaIds to refresh the data
    fetchReservedMasaIds(event);
  };
  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
  };
  const handleRezervClick = async () => {
    setClickCount((prevCount) => prevCount + 1);

    // Calculate timeout duration based on click count
    let timeoutDuration = 0;
    if (clickCount >= 3 && clickCount < 5) {
      timeoutDuration = 3000; // 3 seconds timeout if clicked 3 times
    } else if (clickCount >= 5) {
      timeoutDuration = 5000; // 5 seconds timeout if clicked 5 times
    }
    if (selectedTable === 0) {
      // If no table is selected, show an alert message and reset the state
      setAlertMessage('Zəhmət olmasa masa seçin.');
      setShowAlert(true);
      setMasa(0);
      return;
    }

    if (!selectedTime) {
      // If no time is selected, show an alert message
      setAlertMessage('Zəhmət olmasa rezervasiya üçün vaxt seçin.');
      setShowAlert(true);
      return;
    }

    setIsLoading(true);

    try {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();

      const responseTimeSettings = await axios.get(`${CONN_KEY}Settings.php`);
      const { RezervStart, RezervEnd } = responseTimeSettings.data;

      // Check if the current hour is within the allowed range
      if (currentHour >= RezervStart && currentHour < RezervEnd) {
        const formattedSelectedDay = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        const formattedTime = selectedTime;

        const data = {
          selectedDay: formattedSelectedDay,
          selectedTime: formattedTime,
          selectedFlourId,
          note,
          userId,
          Masa,
        };

        const response = await axios.post(`${CONN_KEY}rezevr.php`, data);

        if (response.status === 200) {
          const audio = new Audio('assets/images/notification_1.mp3');

          if (response.data && response.data.message) {
            setAlertMessage(response.data.message);
            setShowAlert(true);
            setMasa(0);
            setSelectedTime('');
            history.push(`/dashboard/${id}`);
            audio.play();
          } else {
            // Handle case where response message is not provided
            setAlertMessage(response.data.message);
            setShowAlert(true);
          }
        } else {
          // Handle server error response
          console.log('Server returned an error:', response.status, response.statusText);
          setShowAlert(true);
          setAlertMessage('Rezervasiya əlavə etmək alınmadı. Zəhmət olmasa bir az sonra yenə cəhd edin.');
        }
      } else {
        // If the current hour is outside the allowed range, show an alert message
        setAlertMessage(`Rezervasiyaa yalnız ${RezervStart}:00-dan ${RezervEnd}:00'ə qədər icazə verilir.`);
        setShowAlert(true);
      }
    } catch (error) {
      console.log('An error occurred while sending data:', error);
      setAlertMessage('Rezervasiya əlavə etmək alınmadı. Zəhmət olmasa bir az sonra yenə cəhd edin.');
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleButtonClick = (buttonId: number) => {
    setMasa(buttonId);
    setSelectedTable(buttonId);
  };
  const renderMR2Buttons = () => {

    return (
      <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", gap: '3px' }}>
        <div style={{ display: 'flex', gap: '3px', flexDirection: "column-reverse", justifyContent: 'space-evenly' }}>
          {Array.from({ length: 6 }, (_, index) => index + 27).map((masaId) => {
            const isReserved = masaStatuses[masaId] === 'complete';
            const isSelected = selectedTable === masaId;
            const isDisabled = masaId === 22 || masaId === 24;

            return (
              <button
                key={`masa_${masaId}`}
                style={{
                  width: '55px',
                  height: '55px',
                  borderRadius: '12px',
                  backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                }}
                onClick={() => handleButtonClick(masaId)}
                disabled={isDisabled}
              >
                {masaId}
              </button>
            );
          })}
        </div>
        <div style={{ display: 'flex', gap: '3px', flexDirection: "column" }}>
          {Array.from({ length: 8 }, (_, index) => index + 33).map((masaId) => {
            const isReserved = masaStatuses[masaId] === 'complete';
            const isSelected = selectedTable === masaId;
            const isDisabled = masaId === 22 || masaId === 24;

            return (
              <button
                key={`masa_${masaId}`}
                style={{
                  width: '55px',
                  height: '55px',
                  borderRadius: '12px',
                  backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                }}
                onClick={() => handleButtonClick(masaId)}
                disabled={isDisabled}
              >
                {masaId}
              </button>
            );
          })}
        </div>
      </div>
    );
  };
  const renderML2Buttons = () => {
    // ML2 specific buttons
    return (
      <div className="tableButtons">
        {/* ML2 */}
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "column" }}>
            {Array.from({ length: 8 }, (_, index) => index + 1).map((masaId) => {
              const isReserved = masaStatuses[masaId] === 'complete';
              const isSelected = selectedTable === masaId;

              return (
                <button
                  key={`masa_${masaId}`}
                  style={{
                    width: '55px',
                    height: '55px',
                    borderRadius: '12px',
                    backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                  }}
                  onClick={() => handleButtonClick(masaId)}
                >
                  {masaId}
                </button>
              );
            })}
          </div>
          <div style={{ display: 'flex', gap: '3px', flexDirection: "column", justifyContent: 'space-evenly' }}>
            {Array.from({ length: 6 }, (_, index) => index + 9).map((masaId) => {
              const isReserved = masaStatuses[masaId] === 'complete';
              const isSelected = selectedTable === masaId;

              return (
                <button
                  key={`masa_${masaId}`}
                  style={{
                    width: '55px',
                    height: '55px',
                    borderRadius: '12px',
                    backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                  }}
                  onClick={() => handleButtonClick(masaId)}
                >
                  {masaId}
                </button>
              );
            })}
          </div>

        </div>
      </div>
    );
  };
  const renderMM1Buttons = () => {
    // MM1 specific buttons
    return (
      <div className="tableButtons">
        {/* MM1 */}
        <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", gap: '3px' }}>
          <div style={{ display: 'flex', gap: '3px' }}>
            {Array.from({ length: 7 }, (_, index) => index + 20).map((masaId) => {
              const isReserved = masaStatuses[masaId] === 'complete';
              const isSelected = selectedTable === masaId;
              const isDisabled = masaId === 22 || masaId === 24;

              return (
                <button
                  key={`masa_${masaId}`}
                  style={{
                    width: '55px',
                    height: '55px',
                    borderRadius: '12px',
                    backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                  }}
                  onClick={() => handleButtonClick(masaId)}
                  disabled={isDisabled}
                >
                  {masaId}
                </button>
              );
            })}
          </div>
          <div style={{ display: 'flex', gap: '3px' }}>
            {Array.from({ length: 5 }, (_, index) => index + 15).map((masaId) => {
              const isReserved = masaStatuses[masaId] === 'complete';
              const isSelected = selectedTable === masaId;

              return (
                <button
                  key={`masa_${masaId}`}
                  style={{
                    width: '55px',
                    height: '55px',
                    borderRadius: '12px',
                    backgroundColor: isSelected ? 'yellow' : isReserved ? 'red' : '#41FC6A',
                  }}
                  onClick={() => handleButtonClick(masaId)}
                >
                  {masaId}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const renderButtons = () => {
    if (selectedFlourId === null) {
      return <div>Loading...</div>;
    }

    switch (selectedFlourId) {
      case 'MR2':
        return renderMR2Buttons();
      case 'ML2':
        return renderML2Buttons();
      case 'MM1':
        return renderMM1Buttons();
      default:
        console.warn('Unrecognized selectedFlourId:', selectedFlourId);
        return <div>Error: Unrecognized selectedFlourId</div>;
    }
  };
  const renderTime = () => {
    const currentDay = new Date();
    currentDay.setDate(currentDay.getDate() - currentDay.getDay() + selectedDay + (selectedDay === 0 ? 0 : 1));

    return (
      <div className="time-cont">
        {TimeOfDay.map((hour) => {
          const [formattedHour, formattedMinute] = hour.split('.');
          const time = new Date(currentDay);
          time.setHours(parseInt(formattedHour), parseInt(formattedMinute), 0, 0);

          const isSelectedClass =
            (selectedDay === new Date().getDay() &&
              currentTime.getHours() === parseInt(formattedHour) &&
              currentTime.getMinutes() === parseInt(formattedMinute)) ||
            selectedTime === hour;

          return (
            <div
              key={hour}
              className={`timesa ${isSelectedClass ? 'selected' : ''}`}
              onClick={() => handleTimeClick(hour)}
            >
              {hour}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
      <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
        <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='tabbarmains'>
            <IonBackButton defaultHref={id ? `/dashboard/Extra/${id}` : ""} />
          </div>
          <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Reservation</IonTitle>
          <IonButtons slot="primary" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {currentHour >= RezervStart && currentHour < RezervEnd && (
              <>Aktiv  <div className="circle"></div></>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center" style={{ display: 'flex', justifyContent: 'center' }}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <IonRefresherContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}> </IonRefresherContent>
        </IonRefresher>
        <div className='container'>
          {/* <div className='Selected-datetime'>
            <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{todayDate}</IonLabel>
          </div> */}
          <h3 className='title-dayfd'>Masa *</h3>
          <>{renderButtons()}</>
          <div className='Selected-datetime'>
            <h3 className='title-dayfd'>Rezerv vaxtı</h3>
            {renderTime()}
            <h4 className='title-dayfd'>Rezervasiya 23:30-a qədər etibarlıdır.</h4>
          </div>
          {/* <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonLabel position="floating" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Qeyd</IonLabel>
            <IonTextarea
              style={{ padding: '12px' }}
              value={note}
              onIonChange={(e) => setNote(e.detail.value as string)}
              rows={5}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            />
          </IonItem> */}
          <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >Qeyd</IonLabel>
          <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <textarea
              className='ion-input'
              // style={{ padding: '20px', borderRadius: '10px' }}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </IonItem>
          <IonNote color="medium" class="ion-margin-horizontal" style={{ marginBottom: '18px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Qeyd! Burada rezervə aid qeydləri yaza bilərsiz
          </IonNote>
          <IonButton
            className='rzvbuttonWEB'
            // className='rzvbutton'
            expand="block"
            onClick={handleRezervClick} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            Rezerv et
          </IonButton>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            header={'Reservation Message'}
            message={alertMessage}
            buttons={[
              {
                text: 'OK',
                cssClass: 'alert-button-confirm',
              },
            ]}
          />
        </div>
      </IonContent>
      <IonLoading
        isOpen={isLoading}
        onDidDismiss={() => setIsLoading(false)}
        message={'Loading...'}
        duration={5000}
        spinner="dots"
      />
      <IonAlert
        isOpen={showUpdateAlert}
        onDidDismiss={() => setShowUpdateAlert(false)}
        header={'Yenilənmə tələb olunur'}
        message={'Əlçatan yeni yeniləməniz var. Lütfən, yeniləməni tətbiq etmək üçün Google Play Store-a keçin.'}
        buttons={[
          {
            text: 'Update',
            handler: () => {
              // Open the Google Play Store link
              window.open('https://play.google.com/store/apps/details?id=extra.club.baku', '_blank');
            }
          }
        ]}
      />
    </IonPage>
  );
};

export default Masa;
