import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonItem, IonInput, IonButton, IonAlert, IonLabel, IonLoading, IonNote, IonCol, IonRow } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { CONN_KEY } from '../Conn'; // Assuming CONN_KEY and CONN_KEY_version are imported correctly
import { lockClosed, eye } from 'ionicons/icons';

function Register() {
  const history = useHistory();
  const [username, setUsername] = useState<string>("");
  const [Fin, setFin] = useState<string>("");
  const [Pass, setPass] = useState<string>("");
  const [Phone, setPhone] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  useEffect(() => {
    const rememberMePreference = localStorage.getItem('rememberMe');
    setRememberMe(rememberMePreference === 'true');

    if (rememberMePreference === 'true') {
      const savedUsername = localStorage.getItem('username');
      const savedFin = localStorage.getItem('Fin');

      if (savedUsername) {
        setUsername(savedUsername);
      }

      if (savedFin) {
        setFin(savedFin);
      }
    }
  }, []);

  const handleRegister = () => {
    setIsLoading(true);

    const RegisterData = {
      username: username.trim(),
      Fin: Fin.trim().toUpperCase(),
      Phone: Phone.trim(),
      Pass
    };

    if (rememberMe) {
      localStorage.setItem('rememberMe', 'true');
      localStorage.setItem('username', username.trim());
      localStorage.setItem('Fin', Fin.trim().toUpperCase());
      localStorage.setItem('Phone', Phone.trim());
    } else {
      localStorage.removeItem('rememberMe');
    }

    axios.post(`${CONN_KEY}Register.php`, RegisterData)
      .then(res => {
        const { status, id, message } = res.data;

        if (status === 1) {
          // Handle success
          history.push(`/Login`);
        } else {
          setMessage(message);
          setIsError(true);
        }
      })
      .catch(error => {
        setMessage("Authentication failure! Please create an account");
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, maxLength: number) => {
    // Trim and limit input to maxLength characters
    const trimmedValue = value.trim().replace(/\s+/g, '').slice(0, maxLength);
    setValue(trimmedValue);
  };

  const handleUsernameChange = (e: CustomEvent<any>) => {
    const newValue = (e.target as HTMLInputElement).value.replace(/\s/g, '');
    handleInputChange(newValue, setUsername, 50); // Set maximum length for username
  };

  const handleFinChange = (e: CustomEvent<any>) => {
    const newValue = (e.target as HTMLInputElement).value.replace(/\s/g, '');
    handleInputChange(newValue.toUpperCase(), setFin, 20); // Set maximum length for FIN
  };

  const handlePhoneChange = (e: CustomEvent<any>) => {
    const newValue = (e.target as HTMLInputElement).value;
    formatPhoneNumber(newValue); // Format phone number and trim spaces
  };

  const formatPhoneNumber = (phoneValue: string) => {
    const numericValue = phoneValue.replace(/\D/g, '');
    const formattedValue = numericValue.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    setPhone(formattedValue);
  };

  return (
    <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
      <IonContent className="ion-padding ion-text-center container Registersd" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className='container'>
          <IonAlert
            isOpen={isError}
            onDidDismiss={() => setIsError(false)}
            header={"Oops"}
            message={message}
            buttons={["OK"]}
          />
          <h1 style={{ fontWeight: 800 }}>Register</h1>
          <small>By Marketing team</small>
          <div className='Registerformgdsd'>
            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>İstifadəci adı</IonLabel>
              <IonInput
                type="text"
                value={username}
                placeholder="İstifadəci adı"
                onIonChange={handleUsernameChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonItem>
            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>FIN kod</IonLabel>
              <IonInput
                type="text"
                value={Fin}
                placeholder="FIN kod"
                onIonChange={handleFinChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonItem>
            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Telefon</IonLabel>
              <IonInput
                type="tel"
                value={Phone}
                placeholder="Telefon"
                onIonChange={handlePhoneChange}
                maxlength={10} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              <IonNote className="ion-margin-horizontal" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                Format: 050 000 00 00
              </IonNote>
            </IonItem>
            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonLabel position="stacked" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Parol</IonLabel>
              <IonInput
                type='password'
                placeholder="****"
                value={Pass}
                onIonChange={(e) => setPass(e.detail.value!)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonItem>
            <IonRow onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              <IonCol onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonNote color="medium" class="ion-margin-horizontal" style={{ marginBottom: '18px' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                  Hesabınız Var ? <Link to={'/Login'}>Giriş et</Link>
                </IonNote>
              </IonCol>
            </IonRow>
            <IonButton expand="block" onClick={handleRegister} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
              Qeydiyyatdan keçin
            </IonButton>
          </div>
        </div>
        <IonLoading
          isOpen={isLoading}
          message={'Loading...'}
          duration={5000}
          spinner="dots"
        />
      </IonContent>
    </IonPage>
  );
}

export default Register;