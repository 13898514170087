import React, { useState, useEffect } from 'react';
import { IonItem, IonLabel, IonList, IonIcon, IonRouterLink, IonToggle, IonAlert, IonSpinner } from '@ionic/react';
import { moon, chevronForwardOutline, notificationsOutline, keyOutline, personCircleOutline, cardOutline, carSportOutline, phonePortraitOutline } from 'ionicons/icons';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { CONN_KEY } from '../Conn';

interface ProfileComponentLinkProps {
    userId: string;
}

const ProfileComponentLink: React.FC<ProfileComponentLinkProps> = ({ userId }) => {
    const [darkMode, setDarkMode] = useState(() => {
        const darkModeStored = localStorage.getItem('darkMode');
        return darkModeStored ? darkModeStored === 'true' : false;
    });
    const [error, setError] = useState<string>('');
    const [showAlert, setShowAlert] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [PerLoginCihaz, setPerLoginCihaz] = useState(false); // State to track device activity
    const history = useHistory();

    const toggleDarkModeHandler = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode.toString());
        document.body.classList.toggle('dark', newDarkMode);
    };

    const handleChangePhone = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${CONN_KEY}changePhone.php`,
                { userId },
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.data.success) {
                console.log('Phone changed:', response.data);
                setShowConfirmation(true);
            } else {
                console.log('Error changing phone:', response.data.error);
                setError(response.data.error);
            }
        } catch (error) {
            console.log('Error changing phone:', error);
            setError('An error occurred while changing phone.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        document.body.classList.toggle('dark', darkMode);
    }, [darkMode]);

    const confirmChangePhone = () => {
        handleChangePhone();
        setShowAlert(false);
    };

    const handleConfirmationDismiss = () => {
        setShowConfirmation(false);
        history.push('/login');
    };

    useEffect(() => {
        const fetchPerLoginStatus = async () => {
            try {
                const responsePerLogin = await axios.get(`${CONN_KEY}aktivePer.php`);
                const dataPerLogin = responsePerLogin.data;
                console.log('Data PerLogin:', dataPerLogin);
    
                if (dataPerLogin && dataPerLogin.PerLogin) {
                    setPerLoginCihaz(dataPerLogin.PerLogin === 'ok');
                }
            } catch (error) {
                console.log('Error fetching PerLogin status:', error);
            }
        };
    
        fetchPerLoginStatus();
    }, []);

    return (
        <IonList inset onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonRouterLink routerLink={`/dashboard/PersInfo/${userId}`} routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={personCircleOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Hesabım</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            </IonRouterLink>
            <IonRouterLink routerLink={`/dashboard/drivers/${userId}`} routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={carSportOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Sürücülərim</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            </IonRouterLink>
            <IonRouterLink routerLink={`/dashboard/Balance/${userId}`} routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={cardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Balans tarixcəsi</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            </IonRouterLink>
            <IonRouterLink routerLink={`/dashboard/Notify/${userId}`} routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={notificationsOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Bildirişlər</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            </IonRouterLink>
            <IonRouterLink routerLink={`/dashboard/Resetpass/${userId}`} routerDirection="forward" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={keyOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Parolu sıfırlamaq</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            </IonRouterLink>
            {PerLoginCihaz ? (
                <IonItem onClick={() => setShowAlert(true)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonIcon slot="start" icon={phonePortraitOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cihazı Dəyiş</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </IonItem>
            ) : (
                <IonItem button={false} lines="none" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                     {/* <IonIcon slot="start" icon={phonePortraitOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Cihazı Dəyiş</IonLabel>
                    <IonIcon slot="end" icon={chevronForwardOutline} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />  */}
                </IonItem>
            )}
            <IonItem onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonIcon slot="start" icon={moon} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <IonLabel onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Gecə Mode</IonLabel>
                <IonToggle
                    slot="end"
                    name="darkMode"
                    checked={darkMode}
                    onIonChange={toggleDarkModeHandler} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </IonItem>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header={'Cihazı dəyişdirin'}
                message={'Bəli düyməsini sıxsanız, telefonunuz dəyişdiriləcək və siz daha bu cihaza daxil ola bilməyəcəksiniz. Cihazı dəyişmək istədiyinizə əminsiniz?'}
                buttons={[
                    {
                        text: 'İmtina',
                        role: 'cancel',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: 'Bəli, Cihazı dəyişdirin',
                        handler: () => {
                            confirmChangePhone();
                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={showConfirmation}
                onDidDismiss={handleConfirmationDismiss}
                header={'Cihazı dəyişdi'}
                message={'Telefonunuz dəyişdirilib və siz artıq bu cihaza daxil ola bilməyəcəksiniz.'}
                buttons={['OK']}
            />
            {error && (
                <IonAlert
                    isOpen={true}
                    header={'Diqqət'}
                    message={error}
                    buttons={['OK']}
                    onDidDismiss={() => setError('')}
                />
            )}
            <IonAlert
                isOpen={loading} // Display alert if loading is true
                header={'Yüklənir'}
                message={'<div style="display: flex; justify-content: center; align-items: center; height: 100px;"><IonSpinner name="crescent" /></div>'}
                buttons={[]}
                backdropDismiss={false} // Prevent dismissing the alert by clicking the backdrop
            />
        </IonList>
    );
};

export default ProfileComponentLink;
