import React, { useState, useEffect } from 'react';
import { IonTitle, IonToolbar, IonHeader, IonContent, IonBackButton, IonPage, IonButtons } from '@ionic/react';
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { CONN_KEY } from '../Conn';
import { Swiper, SwiperSlide } from 'swiper/react';
import '@ionic/react/css/ionic-swiper.css';
import { Pagination, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import RezervPEllips1 from './RezervPEllips1';
import RezervPEllips2 from './RezervPEllips2';
import RezervPEllips3 from './RezervPEllips3';

interface ResetProps extends RouteComponentProps<{ id: string }> { }

const RezervEllips: React.FC<ResetProps> = (props) => {
    const [currentHour, setCurrentHour] = useState<number>(new Date().getHours());
    const [RezervStart, setRezervStart] = useState<number>(0); // Initialize with default value
    const [RezervEnd, setRezervEnd] = useState<number>(0);
    const [spotlights, setSpotlights] = useState<Array<boolean>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<any>(null);
    const { id } = useParams<{ id: string }>(); // Retrieve selectedFlourId from URL path
    const history = useHistory();
    useEffect(() => {
        const fetchReservationSettings = async () => {
            try {
                const response = await axios.get(`${CONN_KEY}Settings.php`);
                const data = response.data; // Access response data directly from axios response

                if (data && data.RezervStart) {
                    setRezervStart(data.RezervStart); // Set RezervStart state with fetched value
                    setRezervEnd(data.RezervEnd); // Set RezervStart state with fetched value
                } else {
                    console.log('Invalid response format: Missing RezervStart');
                }
            } catch (error) {
                console.log('Error fetching reservation settings:', error);
            }
        };

        fetchReservationSettings();

        // Update current hour every minute to dynamically control spotlight activation
        const intervalId = setInterval(() => {
            setCurrentHour(new Date().getHours());
        }, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        // Initialize spotlights on component mount
        const initializeSpotlights = () => {
            const initialSpotlights = new Array(50).fill(false); // Initialize 50 spotlights as closed
            setSpotlights(initialSpotlights);
        };

        initializeSpotlights();

        // Update spotlights based on current time
        const updateSpotlights = () => {
            setSpotlights(prevSpotlights => (
                prevSpotlights.map((spotlight, index) => {
                    // Activate spotlights only between 9 PM (21) and 6 AM (6)
                    const isActive = (currentHour >= 21 && currentHour <= 23) || (currentHour >= 0 && currentHour < 6);
                    return isActive ? Math.random() < 0.6 : false; // Toggle spotlights randomly if active
                })
            ));
        };

        updateSpotlights(); // Initial update based on current time

        // Update spotlights every minute
        const intervalId = setInterval(() => {
            updateSpotlights();
        }, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [currentHour]);
    const getCookie = (name: string): string | null => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    };
    useEffect(() => {
        // Check if the code is running in a browser environment
        if (typeof window !== 'undefined') {
            const gaULCookie = getCookie('_gaUL');
            if (!gaULCookie || gaULCookie !== id) {
                // Don't perform redirection if _gaUL cookie doesn't exist or doesn't match id
                return;
            }
        }

        setIsLoading(false);

        const fetchUserData = () => {
            axios.get(`${CONN_KEY}Getdata_byid.php?id=${id}`)
                .then(res => {
                    setUser(res.data);
                    // If you have a calculateCountdown function, call it here
                    // calculateCountdown(res.data.endtime);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        fetchUserData(); // Fetch data initially

        const intervalId = setInterval(fetchUserData, 5000); // Fetch data every 5 seconds

        return () => {
            clearInterval(intervalId); // Cleanup the interval when component unmounts
        };
    }, [history, id]);

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <div className='tabbarmains'>
                        <IonBackButton defaultHref={user ? `/dashboard/rezervmap/${user.id}` : ""} />
                    </div>
                    <IonTitle style={{ zIndex: '-1' }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Rezerv Ellips</IonTitle>
                    <IonButtons slot="primary" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        {currentHour >= RezervStart && currentHour < RezervEnd && (
                            <div className="circle"></div>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding ion-text-center" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <div className="infosds">
                    <div className="ckdj">
                        <div style={{ backgroundColor: 'red', width: '20px', height: '20px', marginRight: 5, borderRadius: '1px' }}></div>
                        <p style={{ fontSize: '16px' }}>Rezerv edilib</p>
                    </div>
                    <div className="ckdj">
                        <div style={{ backgroundColor: 'green', width: '20px', height: '20px', marginRight: 5, borderRadius: '1px' }}></div>
                        <p style={{ fontSize: '16px' }}>Boş masalar</p>
                    </div>
                </div>
                <Swiper
                    modules={[Pagination, Zoom]}
                    zoom={{
                        toggle: true,
                        maxRatio: 2,
                        minRatio: 1
                    }}
                    pagination={{
                        type: 'progressbar'
                    }}
                >
                    <SwiperSlide><RezervPEllips1 {...props} /></SwiperSlide>
                    <SwiperSlide><RezervPEllips2 {...props} /></SwiperSlide>
                    <SwiperSlide><RezervPEllips3 {...props} /></SwiperSlide>
                </Swiper>
            </IonContent>
        </IonPage>
    );
};

export default RezervEllips;
