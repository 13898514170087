import React, { useState, useEffect } from 'react';
import axios from "axios";
import { IonBackButton, IonButtons, IonButton, IonPage, useIonViewDidEnter, IonHeader, IonContent, IonToolbar, IonTitle, IonItem, IonList, IonInput, IonLabel, IonAlert } from '@ionic/react';
import { CONN_KEY, CONN_KEYAS } from '../Conn';
import { RouteComponentProps } from "react-router-dom";

interface ResetProps extends RouteComponentProps<{ id: string }> { }

const PersonInformation: React.FC<ResetProps> = ({ match }) => {
    const [user, setUser] = useState<any>(null);
    const [editedUser, setEditedUser] = useState<any>({});
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

    useEffect(() => {
        // Fetch user data when component mounts
        fetchData();
    }, []);

    const fetchData = async () => {
        const userId = match.params.id;

        try {
            const response = await axios.get(`${CONN_KEY}Getdata_byid.php?id=${userId}`);
            setUser(response.data);
            // Set the userid in the editedUser state
            setEditedUser({ ...response.data, userid: userId });
        } catch (error) {
            console.log("Error fetching user data:", error);
        }
    };

    // const handleInputChange = (e: CustomEvent) => {
    //     const value = (e.target as HTMLInputElement).value;
    //     const name = (e.target as HTMLInputElement).name;

    //     setEditedUser((prevState: any) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    // const updateUser = async () => {
    //     try {
    //         // Include the userid in the URL as a query parameter
    //         const updatedUserData = { ...editedUser };
    //         const userId = match.params.id;

    //         const response = await axios.put(`${CONN_KEY}UpdateUser.php?id=${userId}`, updatedUserData);
    //         // Optionally, you can fetch updated data after successful update
    //         fetchData();
    //         setShowSuccessAlert(true); // Show success alert
    //     } catch (error) {
    //         console.log("Error updating user:", error);
    //     }
    // };

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonHeader onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonButtons slot="start" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Şəxsi məlumat</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} fullscreen className="ion-padding ion-text-center">
                {user && (
                    <IonList onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <img alt='pp' className='pp'
                                onError={(e) => {
                                    e.currentTarget.src = "https://svurguns.com/Data/api/3x4/scan_65e790c5777037.70195643.png";
                                    e.currentTarget.onerror = null;
                                }}
                                src={`https://svurguns.com/Data/api/3x4/${user.filePP}`}
                            />
                        </IonLabel>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.username}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >ID:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>#{user.userid}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Soy Adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.surename}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Ata adı:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.dadname}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Fin:</IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.Fin}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Doğum gününü: </IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.datebhrd}</IonLabel>
                        </IonItem>
                        <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ color: 'var(--mainpagebuttons)' }} >Telefon: </IonLabel>
                            <IonLabel className='user-p' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{user.Phone}</IonLabel>
                        </IonItem>
                    </IonList>
                )}
                <IonAlert
                    isOpen={showSuccessAlert}
                    onDidDismiss={() => setShowSuccessAlert(false)}
                    header={'Success'}
                    message={'İstifadəçi məlumatı uğurla yeniləndi!'}
                    buttons={['OK']}
                />
            </IonContent>
        </IonPage>
    );
}

export default PersonInformation;
