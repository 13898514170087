import React, { useState, useEffect } from 'react';
import { IonContent, IonItem, IonLabel, IonPage } from '@ionic/react';
import PrifileComponentLink from './ProfileCompenentLink';
import { CONN_KEY_version } from '../Conn';

interface SettingsProps {
    userId: string;
}

const Settings: React.FC<SettingsProps> = ({ userId }) => {

    return (
        <IonPage onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <IonContent onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <PrifileComponentLink userId={userId} />
                <IonItem className='list' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonLabel className='user-p version' onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <h6>Applications version {CONN_KEY_version}</h6>
                        <small>By Marketing Team</small>
                    </IonLabel>
                </IonItem>
            </IonContent>
        </IonPage>
    );
}

export default Settings;
